// import * as npm from "../../../package.json";


export const environment = {
  production: false,
  env: "dev",
  // version: npm.version,
  authUrl: 'https://topsalon-auth-dev.cdfb.it',
  apiUrl: 'https://topsalon-api-dev.cdfb.it',
  mediaUrl: 'https://topsalon-media-dev.cdfb.it'
};

